import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let adminId;
const superAdminRoute = "/api/getPrepped/superAdmin";

let setTokenValues = () => {
    orgId = localStorage.getItem("orgId");
    token = localStorage.getItem("token");
    adminId = localStorage.getItem("adminId");
};

export const getAllMentors = createAsyncThunk("admin/getAllMentors", async (thunkAPI) => {
    try {
        setTokenValues();

        const response = await fetch(environment.baseURL +
            superAdminRoute +
            "/getAllMentors",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllUpcomingCalls = createAsyncThunk("admin/getAllUpcomingCalls", async (thunkAPI) => {
    try {
        setTokenValues();

        const response = await fetch(environment.baseURL +
            superAdminRoute +
            "/getAllUpcomingCalls",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);
export const getAllPastCalls = createAsyncThunk("admin/getAllPastCalls", async (thunkAPI) => {
    try {
        setTokenValues();

        const response = await fetch(environment.baseURL +
            superAdminRoute +
            "/getAllPastCalls",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getSpecificMentor = createAsyncThunk("admin/getSpecificMentor", async (mentorId, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            superAdminRoute +
            "/mentor/" +
            mentorId +
            "/getSpecificMentorDetails",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllMentee = createAsyncThunk("admin/getAllMentee", async (mentorId, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            superAdminRoute + "/getAllMentee"
            ,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllMentorRequests = createAsyncThunk("admin/getAllMentorRequests", async (mentorId, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            superAdminRoute + "/getAllMentorRequests"
            ,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getPotentialMentorDetail = createAsyncThunk("admin/getPotentialMentorDetail", async (mentorId, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            superAdminRoute + `/mentor/${mentorId}/getPotentialMentorDetail`

            ,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);


export const getMenteePastCalls = createAsyncThunk("admin/getMenteePastCalls", async (menteeId, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            superAdminRoute +
            "/mentee/" +
            menteeId +
            "/getMenteePastCalls"
            ,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const addAdmin = createAsyncThunk(
    "admin/addAdmin",
    async (obj, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL + superAdminRoute + `/${adminId}` + "/addAdmin",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    body: JSON.stringify(obj)

                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                window.location.reload();
            }
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const rejectMentorRequest = createAsyncThunk(
    "admin/rejectMentorRequest",
    async (obj, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                superAdminRoute + "/rejectMentorRequest",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    body: JSON.stringify(obj)
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                window.location.reload();
            }
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const acceptMentorRequest = createAsyncThunk(
    "admin/acceptMentorRequest",
    async (obj, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                superAdminRoute +
                "/acceptMentorRequest",

                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    body: JSON.stringify(obj)
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                window.location.reload();
            }
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const deleteMentor = createAsyncThunk(
    "admin/deleteMentor",
    async (mentorId, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                superAdminRoute +
                "/" +
                mentorId +
                "/deleteMentor",

                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                window.location.reload();
            }
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getAllEvents = createAsyncThunk("admin/getAllEvents", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            superAdminRoute + "/getAllEvents"
            ,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllEventRegistrations = createAsyncThunk("admin/getAllEventRegistrations", async (eventId ,thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            superAdminRoute +
             "/" +
            eventId +
            "/getAllEventRegistrations"
            ,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data?.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const adminSlice = createSlice({
    name: "admin",
    initialState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
        potentialMentorData: [],
        allMentorRequestsData: [],
        menteeList: [],
        allMentors: [],
        allUpcomingCalls: [],
        allPastCalls: [],
        menteePastCalls: [],
        mentorDetail: [],
        allEvents: [],
        eventRegistrations: [],
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            // state.isFetching = false;
            return state;
        },
    },
    extraReducers: {
        [getAllMentors.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllMentors.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allMentors = payload.Data;
            return state;
        },
        [getAllMentors.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllUpcomingCalls.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllUpcomingCalls.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allUpcomingCalls = payload.Data;
            return state;
        },
        [getAllUpcomingCalls.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllPastCalls.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllPastCalls.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allPastCalls = payload.Data;
            return state;
        },
        [getAllPastCalls.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getSpecificMentor.pending]: (state) => {
            state.isFetching = true;
        },
        [getSpecificMentor.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.mentorDetail = payload.Data;
            return state;
        },
        [getSpecificMentor.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllMentee.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllMentee.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.menteeList = payload.Data;
            return state;
        },
        [getAllMentee.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllMentorRequests.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllMentorRequests.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allMentorRequestsData = payload.Data;
            return state;
        },
        [getAllMentorRequests.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getPotentialMentorDetail.pending]: (state) => {
            state.isFetching = true;
        },
        [getPotentialMentorDetail.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.potentialMentorData = payload.Data;
            return state;
        },
        [getPotentialMentorDetail.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [addAdmin.pending]: (state) => {
            state.isFetching = true;
        },
        [addAdmin.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addAdmin.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [rejectMentorRequest.pending]: (state) => {
            state.isFetching = true;
        },
        [rejectMentorRequest.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [rejectMentorRequest.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [acceptMentorRequest.pending]: (state) => {
            state.isFetching = true;
        },
        [acceptMentorRequest.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [acceptMentorRequest.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [deleteMentor.pending]: (state) => {
            state.isFetching = true;
        },
        [deleteMentor.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteMentor.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getMenteePastCalls.pending]: (state) => {
            state.isFetching = true;
        },
        [getMenteePastCalls.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.menteePastCalls = payload?.Data;
            return state;
        },
        [getMenteePastCalls.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllEvents.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllEvents.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allEvents = payload?.Data;
            return state;
        },
        [getAllEvents.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllEventRegistrations.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllEventRegistrations.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.eventRegistrations = payload;
            return state;
        },
        [getAllEventRegistrations.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
    }
});



export const { clearState } = adminSlice.actions;

export const adminSliceData = (state) => state.admin;