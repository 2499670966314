import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import PageLoader from '../../../components/PageLoader/PageLoader';
import { getFeedbackAnswers, mentorSliceData } from '../../../reducer/mentorSlice';

const AllCallDetails = () => {

    const [toggle, setToggle] = useState(false);
    const [isMentor, setIsMentor] = useState(true);
    const [menteeFeedback, setMenteeFeedback] = useState([]);
    const [mentorFeedback, setMentorFeedback] = useState([]);

    const { feedbackAnswers, isFetching, isError, errorMessage } = useSelector(mentorSliceData);
    const dispatch = useDispatch();
    const { id } = useParams();
    const ratingArray = [1, 2, 3, 4, 5];

    useEffect(() => {
        dispatch(getFeedbackAnswers(id));
    }, []);

    useEffect(() => {
        setMentorFeedback(
            feedbackAnswers?.getPrepFeedbackAnswers?.filter((item) => item.mentorId !== null)
        );
        setMenteeFeedback(
            feedbackAnswers?.getPrepFeedbackAnswers?.filter((item) => item.menteeId !== null)
        );
    }, [feedbackAnswers]);

    return (
        <>
        {isFetching && <PageLoader />}
            <div className="allCallDetailsWrap viewFeedbackWrappper">
                <div className="profileHeader">
                    <h2 className="heading-3">Feedback Received / View Details</h2>
                </div>

                <div className="FeedbackWrap">

                    <div className="mentorProfile">
                        <img src={isMentor ? !feedbackAnswers?.GetPreppedAdmin?.profileImg ? "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png" : feedbackAnswers?.GetPreppedAdmin?.profileImg  : !feedbackAnswers?.GetPreppedMentee?.profileImg ? "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png" : feedbackAnswers?.GetPreppedMentee?.profileImg} alt="" className="mentorImg" />
                        <div className="mentorDetails">
                            <p className="mentorName">{isMentor ? (feedbackAnswers?.GetPreppedAdmin?.firstName ? `${feedbackAnswers?.GetPreppedAdmin?.firstName} ${feedbackAnswers?.GetPreppedAdmin?.lastName}` : "---") : (feedbackAnswers?.GetPreppedMentee?.firstName ? `${feedbackAnswers?.GetPreppedMentee?.firstName} ${feedbackAnswers?.GetPreppedMentee?.lastName}` : "---") }</p>
                            <p className="designation">{isMentor ? (feedbackAnswers?.GetPreppedAdmin?.GetPrepProfession?.designation ? `${feedbackAnswers?.GetPreppedAdmin?.GetPrepProfession?.designation} ${feedbackAnswers?.GetPreppedAdmin?.GetPrepProfession?.company}` : "---") : (feedbackAnswers?.GetPreppedMentee?.menteeType ? feedbackAnswers?.GetPreppedMentee?.menteeType : "---")}</p>
                            {/* <p className="designation">Director, KPMG in India</p> */}
                        </div>
                        {/* <div className="linkdinBtn" onClick={() => window.open("Hello" + isMentor ? feedbackAnswers?.GetPreppedAdmin?.linkedin : feedbackAnswers?.GetPreppedMentee?.linkedin)}>
                            Linkedin
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="
                            none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8125 13.75H2.1875" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.875 2.1875L3.125 10.9375" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.875 8.60625V2.1875H5.45625" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div> */}
                    </div>

                    {/* <div className="callTypes mt-20"></div> */}

                    {/* <h3 className="feedbackSmallHeading mt-20">Feedback</h3> */}

                    <div className="callTypes mt-30">
                        <Button className={`callButton ${isMentor && "active"}`} name={'Mentor Feedback'} onClick={e => setIsMentor(true)} />
                        <Button className={`callButton ${!isMentor && "active"}`} name={'Mentee Feedback'} onClick={e => setIsMentor(false)} />
                    </div>

                    <div className="scheduleData mt-30">

                        <div className={`tabCall ${isMentor && "active"}`}>
                            {mentorFeedback?.length > 0 ? mentorFeedback?.map((item, index) => (
                                <>
                                    <div className="feedbackBoxAll">
                                        <div className="feedbackQuestion">How was your experience with the mentee?</div>
                                        <div className="ratingScale mt-15">
                                            <span className="useless">0</span>
                                            {ratingArray.map((value) => (
                                                <span className={item?.answer1 >= value && "active"}>{value}</span>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">What was the mentee’s problem about?</div>
                                        <div className="feedbackAnswer mt-5">{item?.answer2 ?? "---"}</div>
                                    </div>

                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">Were you able to come up with an action plan for the mentee?</div>
                                        <div className="feedbackAnswer mt-5">{item?.answer3 ?? "---"}</div>
                                    </div>

                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">How would you rate the mentee’s employability, if you were to hire them for your own team?</div>
                                        <div className="ratingScale mt-15">
                                            <span className="useless">0</span>
                                            {ratingArray.map((value) => (
                                                <span className={item?.answer4 >= value && "active"}>{value}</span>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )) : "No feedback received"}

                        </div>

                        <div className={`tabCall ${!isMentor && "active"}`}>

                            {menteeFeedback?.length > 0 ? menteeFeedback?.map((item, index) => (
                                <>
                                    <div className="feedbackBoxAll">
                                        <div className="feedbackQuestion">How was your experience with the mentee?</div>
                                        <div className="ratingScale mt-15">
                                            <span className="useless">0</span>
                                            {ratingArray.map((value) => (
                                                <span className={item.answer1 >= value && "active"}>{value}</span>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">Did the call help you get any clarity?</div>
                                        <div className="feedbackAnswer mt-5">{item.answer2}</div>
                                    </div>

                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">Would you like to schedule a call again with the same mentor?</div>
                                        <div className="feedbackAnswer mt-5">{item.answer3}</div>
                                    </div>

                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">Was the mentor able to answer your questions?</div>
                                        <div className="feedbackAnswer mt-5">{item.answer4}</div>
                                    </div>
                                </>
                            )) : "No feedback received"
                            }

                        </div>


                    </div>

                </div>

            </div>
        </>
    )
}

export default AllCallDetails