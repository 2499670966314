import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { adminSliceData, getAllPastCalls, getAllUpcomingCalls } from '../../../reducer/adminSlice';
import "./CallDetailsStyle.css";
import '../../Mentor/callsBooking/CallsBookingStyle.css';

const CallDetails = () => {

    const [upcomingCallsToggler, setUpcomingCallsToggler] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isFetching, allPastCalls, allUpcomingCalls } = useSelector(adminSliceData);

    useEffect(() => {
        if (upcomingCallsToggler) dispatch(getAllUpcomingCalls());
        else dispatch(getAllPastCalls());
    }, [upcomingCallsToggler])

    return (
        <>
            {isFetching && <PageLoader />}
            <div className="dashboardContainer">
                <div className="pageHeader">
                    <p className="heading">All Call</p>
                </div>

                <div className="AdminCallWrap">

                    <div className="callTypes">
                        <Button className={`callButton ${upcomingCallsToggler && "active"}`} onClick={() => setUpcomingCallsToggler(true)} name={'Upcoming Calls'} />
                        <Button className={`callButton ${!upcomingCallsToggler && "active"}`} onClick={() => setUpcomingCallsToggler(false)} name={'Past Calls'} />
                    </div>

                    <div className="scheduleData">

                        <div className={`tabCall ${upcomingCallsToggler && "active"}`}>
                            <table className="studentTable" cellSpacing={0} cellPadding={0}>
                                <thead>
                                    <tr>
                                        <th>Mentor</th>
                                        <th>Mentee</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUpcomingCalls?.length > 0 ? allUpcomingCalls?.map((item, index) => (
                                        <tr>
                                            <td>
                                                <div className="studentInfo">
                                                    <img src={item?.profileImg ? item?.profileImg : "/assets/img/demoUser.png"} alt="" />
                                                    {item?.GetPreppedAdmin?.firstName ? `${item?.GetPreppedAdmin?.firstName} ${item?.GetPreppedAdmin?.lastName}` : "---"}
                                                </div>
                                            </td>
                                            <td>{item?.GetPreppedMentee?.firstName ? `${item?.GetPreppedMentee?.firstName} ${item?.GetPreppedMentee?.lastName}` : "---"}</td>
                                            <td>{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("ddd DD MMM YYYY") : "---"}</td>
                                            <td>{item?.time ? item?.time : "---"}</td>
                                        </tr>
                                    )) : "No data available"}
                                </tbody>
                            </table>
                        </div>

                        <div className={`tabCall ${!upcomingCallsToggler && "active"}`}>
                            <table className="studentTable" cellSpacing={0} cellPadding={0}>
                                <thead>
                                    <tr>
                                        <th>Mentor</th>
                                        <th>Mentee</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allPastCalls?.length >0 ? allPastCalls?.map((item,index) => (
                                        <tr key={index}>
                                        <td>
                                            <div className="studentInfo">
                                                <img src={item?.profileImg ? item?.profileImg : "/assets/img/demoUser.png"} alt="" />
                                                {item?.GetPreppedAdmin?.firstName ? `${item?.GetPreppedAdmin?.firstName} ${item?.GetPreppedAdmin?.lastName}` : "---"}
                                            </div>
                                        </td>
                                        <td>{item?.GetPreppedMentee?.firstName ? `${item?.GetPreppedMentee?.firstName} ${item?.GetPreppedMentee?.lastName}` : "---"}</td>
                                        <td>{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("ddd DD MMM YYYY") : "---"}</td>
                                        <td>{item?.time ? item?.time : "---"}</td>
                                        <td> <button onClick={() => navigate(`/allCallDetails/${item?.id}`)} className="primaryBtn">View Details</button> </td>
                                    </tr>
                                    )) : "No data available"}
                                    {/* <tr>
                                        <td>
                                            <div className="studentInfo">
                                                <img src="/assets/img/demoUser.png" alt="" />
                                                Subhro Bhattacharya
                                            </div>
                                        </td>
                                        <td>Skill Development</td>
                                        <td>Subhro Bhattacharya@gmail.com</td>
                                        <td>89564-85236</td>
                                        <td> <button onClick={() => navigate('/mentorProfile')} className="primaryBtn">View Details</button> </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default CallDetails