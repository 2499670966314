import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { adminSliceData, getAllMentorRequests } from '../../../reducer/adminSlice'
import PageLoader from '../../../components/PageLoader/PageLoader'



const MentorRequestList = () => {
    const { isFetching, allMentorRequestsData } = useSelector(adminSliceData);

    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllMentorRequests())
    }, [])



    return (
        <div className="menteeOuter">
            {isFetching && <PageLoader />}
            <div className="pageHeader">
                <p className="heading">Mentors New Request</p>

            </div>
            <div className="menteeContainer menteeList">
                <table className="studentTable" cellSpacing={0} cellPadding={0}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Area of expertise</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Feedback</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allMentorRequestsData?.length > 0 ? allMentorRequestsData?.map((val, index) => <tr key={index}>
                            <td>
                                <div className="studentInfo">
                                    <img src={val.profileImg ? val.profileImg : "/assets/img/demoUser.png"} alt="" />
                                    {val.firstName ? `${val.firstName} ${val.lastName}` : "---"}
                                </div>
                            </td>
                            <td>{val.GetPrepInterests.length > 0 ? val.GetPrepInterests[0].interest : "---"}</td>
                            <td>{val.email ? val.email : "---"}</td>
                            <td>{val.mobileNumber ? val.mobileNumber : "---"}</td>
                            <td> <button className="primaryBtn" onClick={() => navigate(`/mentorRequest/${val.id}`)}>View Request</button> </td>
                        </tr>) : "No data available"}
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default MentorRequestList