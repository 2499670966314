import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import PageLoader from '../../../components/PageLoader/PageLoader'
import { adminSliceData, getAllMentee } from '../../../reducer/adminSlice'
import './MenteeListStyle.css'

const MenteeList = () => {

    const dispatch = useDispatch();
    const { isFetching, menteeList } = useSelector(adminSliceData);


    useEffect(() => {
        dispatch(getAllMentee());
    }, [])

    const navigate = useNavigate()
    const handleNavigate = (id) => {
        navigate(`/pastCallList/${id}`, { exact: true })
    }



    return (
        <div className="menteeOuter">
            {isFetching && <PageLoader />}

            <div className="pageHeader">
                <p className="heading">Mentee List</p>
            </div>
            <div className="tableContainer menteeList">
                <table className="studentTable" cellSpacing={0} cellPadding={0}>
                    <thead>
                        <tr>
                            <th>Mentee</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {menteeList?.length > 0 ? menteeList?.map((val, index) => <tr key={index}>
                            <td key={index}>
                                <div className="studentInfo">
                                    <img src={val?.profileImg ? val?.profileImg : "/assets/img/demoUser.png"} alt="" />
                                    {val?.firstName ? `${val?.firstName} ${val?.lastName}` : "---"}
                                </div>
                            </td>
                            <td>{val?.email ? val?.email : "---"}</td>
                            <td>{val?.menteeType ? val?.menteeType : "---"}</td>
                            <td>{val.slotDate ? moment(val.slotDate).format("ddd DD MMM YYYY") : "---"}</td>
                            <td> <button className="primaryBtn" onClick={() => handleNavigate(val.id)}>View Details</button> </td>
                        </tr>) : "No data available"}
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default MenteeList