import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import Menu from "./components/menu/Menu";
import MentorRequest from "./pages/Admin/mentorRequest/MentorRequest";
import Authentication from "./pages/authentication/Authentication";
import { PrivateRoute } from "./helpers/PrivateRoute";
import Dashboard from "./pages/Admin/dashboard/Dashboard";
import Feedback from "./pages/Admin/feedback/Feedback";
import MenteeList from "./pages/Admin/menteeList/MenteeList";
import MentorProfile from "./pages/Admin/mentorProfile/MentorProfile";
import MentorRequestList from "./pages/Admin/mentorRequest/MentorRequestList";
import CallDetails from "./pages/Admin/callDetails/CallDetails";
import PastCallList from "./pages/Admin/pastCallList/PastCallList";
import AllCallDetails from "./pages/Admin/allCallDetails/AllCallDetails";
import Events from "./pages/Admin/Events/Events";
import { useSelector } from "react-redux";
import { userSelector } from "./reducer/userSlice";
import { useState } from "react";
import ConfirmPassword from "./pages/authentication/ConfirmPassword";
import EventLeads from "./pages/Admin/Events/EventLeads";

function App() {

  const [isMenu, setIsMenu] = useState(false)

  const { token } = useSelector(userSelector);

  const pathname = window.location.pathname;
  return (
    <>
      <BrowserRouter>
        <Header setIsMenu={setIsMenu} isMenu={isMenu} />
        <div className="appContainer">
          {((pathname !== "/authentication" && pathname !== "/createFeedback") && token !== null) && <Menu isMenu={isMenu} setIsMenu={setIsMenu} />}
          <div className={`routesContainer ${(pathname === "/authentication" || pathname === "/createFeedback") || token === null ? "LoginWrapper" : ""}`} >
            <Routes>

              {/* Admin Routes */}
              <Route path="/authentication" exact element={<Authentication />} />
              <Route path='/changePassword/:token' element={<ConfirmPassword />} />

              <Route path="/" exact element={<PrivateRoute />} >
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/mentorProfile/:mentorId" exact element={<MentorProfile />} />
                <Route path="/feedback" exact element={<Feedback />} />
                <Route path="/menteeList" exact element={<MenteeList />} />
                <Route path="/mentorRequestList" exact element={<MentorRequestList />} />
                <Route path="/mentorRequest/:id" exact element={<MentorRequest />} />
                <Route path="/pastCallList/:id" exact element={<PastCallList />} />
                <Route path="/allCallDetails/:id" exact element={<AllCallDetails />} />
                <Route path="/callDetails" exact element={<CallDetails />} />

                <Route path="/events" exact element={<Events />} />
                <Route path="/eventLeads/:id" exact element={<EventLeads />} />
              </Route>

            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
