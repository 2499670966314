import React, { useState } from "react";
import "./MeetingSchedulerPopoupStyle.css";
import { Calendar } from "react-calendar";

const MeetingSchedulerPopoup = ({isActive, setSelectedDate}) => {
  const [value, onChange] = useState();
  return (
    <div className={`popupOuter ${isActive && "active"}`}>
      <div className="popupContainer">
        <div className="popupHeader">
          <p className="popupTitle">Select a Date & Time</p>
        </div>
        <div className="popupBody">
          <div className="schedulerContainer">
            <div className="calendarOuter">
              <Calendar minDate={new Date()} onChange={setSelectedDate} />
            </div>
            <div className="selectInterviewContainer">
              <p className="month">November 2022</p>
              <div className="slotsOuter">
                <div className="slots">
                  <div className="slot">11:00 am</div>
                  <button className="primaryBtn">Confirm</button>
                </div>
                <div className="slots">
                  <div className="slot">11:30 am</div>
                  <button className="primaryBtn">Confirm</button>
                </div>
                <div className="slots">
                  <div className="slot">12:00 pm</div>
                  <button className="primaryBtn">Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingSchedulerPopoup;
