import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader";
import "./DashboardStyle.css";
import {
  addAdmin,
  adminSliceData,
  getAllMentors,
  clearState,
  deleteMentor,
} from "../../../reducer/adminSlice";
import AddMentorPopup from "../../../components/popups/AddMentorPopup/AddMentorPopup";
import { useState } from "react";
import ErrorToast from "../../../components/Toast/ErrorToast";
import Button from "../../../components/Button/Button";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef();

  const { isFetching, allMentors, isSuccess, isError, errorMessage } = useSelector(adminSliceData);

  const data = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    company: "",
    linkedin: "",
    designation: "",
    yrsOfExp: "",
    interests: [],
    topics: [],
    organizationId: ""
  };

  const [postData, setPostData] = useState({ ...data });
  const [popupToggler, setPopupToggler] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState("");

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    dispatch(getAllMentors());
  }, []);

  const handleChange = (e) => {
    const { id, value, name } = e.target;
    if (id === "mobileNumber" && value.length > 10) return;
    if (id === "yrsOfExp" && value.length > 2) return;
    if (name !== "interests" && name !== "topics")
      setPostData((val) => ({ ...val, [id]: value }));
    else {
      if (postData[name].includes(value)) {
        setPostData((val) => ({
          ...val,
          [name]: [...postData[name].filter((item) => item !== value)],
        }));
      } else
        setPostData((val) => ({ ...val, [name]: [...postData[name], value] }));
    }
  };

  const handleClose = () => {
    setActiveDropdown("");
    setPopupToggler(false);
    setPostData({ ...data });

  };
  const handleSubmit = () => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(postData["email"])) {
      setIsShowError(true);
      setErrMessage("Please enter valid email");
      return;
    }
    for (const key in postData) {
      if (key != "organizationId")
        if (postData[key] == "" || postData[key].length == 0) {
          setIsShowError(true);
          setErrMessage("All fields are mandatory!")
          return null;
        }
    }
    dispatch(addAdmin(postData));
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      dispatch(getAllMentors());
      dispatch(clearState());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  });

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(e) {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
          setActiveDropdown("");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef]);

    const handleDeleteMentor = () => {
      dispatch(deleteMentor(activeDropdown));
    }

    return (
      <>
        <ErrorToast
          show={isShowError}
          setShow={() => setIsShowError(false)}
          message={errMessage}
        />
        <AddMentorPopup
          show={popupToggler}
          handleChange={handleChange}
          data={postData}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
        {isFetching && <PageLoader />}
        <div className="dashboardContainer">
          <div className="pageHeader">
            <p className="heading">All Mentors</p>
            <Button className={'outlineBtn mentorBtn'} name={'Add Mentor'} onClick={() => { setPopupToggler(!popupToggler); setActiveDropdown("") }} />
          </div>
          <div className="tableWrap">
            <div className="tableContainer">
              <table className="studentTable" cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Career Advice</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                                  <td>
                                      <div className="studentInfo">
                                          <img src="/assets/img/demoUser.png" alt="" />
                                          Subhro Bhattacharya
                                      </div>
                                  </td>
                                  <td>Skill Development</td>
                                  <td>Subhro Bhattacharya@gmail.com</td>
                                  <td>89564-85236</td>
                                  <td> <button onClick={() => navigate('/mentorProfile')} className="primaryBtn">View Profile</button> </td>
                              </tr> */}
                  {allMentors?.length > 0
                    ? allMentors?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="studentInfo">
                            <img
                              src={item?.profileImg ? item?.profileImg : "/assets/img/demoUser.png"}
                              alt=""
                            />
                            {item?.firstName ? `${item?.firstName} ${item?.lastName}` : "---"}
                          </div>
                        </td>
                        <td>{item?.GetPrepTopics?.length > 0 ? item?.GetPrepTopics[0]?.Topic : "---"}</td>
                        <td>{item?.email ? item?.email : "---"}</td>
                        <td>{item?.mobileNumber ? item?.mobileNumber : "---"}</td>
                        <td>
                          {" "}

                          {/* <button
                            onClick={() => navigate(`/mentorProfile/${item.id}`)}
                            className="primaryBtn"
                          >
                            View Profile
                          </button> */}

                          <div className="pullDownWrapper">
                            <div className="dotIcon" onClick={() => activeDropdown === item?.id ? setActiveDropdown("") : setActiveDropdown(item?.id)}>
                              <img src="/assets/img/dostOption.svg" />
                            </div>
                            <div className={`pullDownMenu ${activeDropdown === item?.id && "active"}`} ref={dropdownRef} >
                              <span className="pullMenu" onClick={() => navigate(`/mentorProfile/${item.id}`)} >View Profile</span>
                              <span className="pullMenu" onClick={handleDeleteMentor} >Delete</span>
                            </div>
                          </div>
                          {" "}
                        </td>
                      </tr>
                    ))
                    : "No data available"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  export default Dashboard;
