import React from 'react'
import Button from '../../Button/Button';
import InputField from '../../InputField/InputField';
import './AddMentorPopupStyle.css';
import "../../../pages/Mentor/editProfile/EditProfileStyle.css";

const AddMentorPopup = ({show,handleChange,data,handleClose,handleSubmit}) => {

  const areasOfInterest = [
    "Taxation",
    "CA/CMA/CFA",
    "CAT/MBA",
    "Financial Advisory",
    "Investment Banking",
    "Auditing",
    "FP&A",
    "Financial Reporting",
    "Mergers & Acquisitions",
    "Accounting",
    "Financial Analysis",
    "Risk Management",
    "Asset Management",
    "Valuations"
  ];

  const topics = [
    "Career Advice",
    "Job Search",
    "Skill Development",
    "Competitive Exams",
    "Career Transition"
  ];

  return (
   
    <div className={`popupOuter addMentorPopup ${show?"active":""}`}>
      <div className="popupContainer">
        <div className="popupHeader">
          <p className="popupTitle">Add Mentor</p>
          <img src='/assets/img/Close.svg' className='closeWhite pointer' onClick={handleClose}/>
        </div>
        <div className="popupBody">
          <div className="mentorContainer">
            <div className='flexWrapBox'>
              <InputField type={'text'} placeholder={'Enter your first name'} id={'firstName'} labelName={'First Name'} onChange={handleChange} value={data.firstName}/>
              <InputField type={'text'} placeholder={'Enter your last name'} id={'lastName'} labelName={'Last Name'} onChange={handleChange} notImp={true} value={data.lastName}/>
            </div>
            <div className='flexWrapBox mt-30'>
            <InputField type={'email'} placeholder={'Enter your email'} id={'email'} labelName={'Email'} onChange={handleChange} value={data.email}/>
              <InputField type={'number'} placeholder={'Enter your Contact Number'} id={'mobileNumber'} labelName={'Number'} onChange={handleChange} value={data.mobileNumber}/>
            </div>
            <div className='flexWrapBox mt-30'>
            <InputField type={'text'} placeholder={'Current Company Name'} id={'company'} labelName={'Company'} onChange={handleChange} value={data.company}/>
              <InputField type={'text'} placeholder={'Enter your Designation'} id={'designation'} labelName={'Designation'} onChange={handleChange} value={data.designation}/>

            </div>
            <div className='flexWrapBox mt-30'>
            <InputField type={'number'} placeholder={'Years of Experience'} id={'yrsOfExp'} labelName={'Experience'} onChange={handleChange} value={data.yrsOfExp}/>
            <InputField type={'text'} placeholder={'Enter your Linkedin URL'} id={'linkedin'} labelName={'Linkedin URL'} onChange={handleChange} value={data.linkedin}/>

            </div>
            <div className="formGroup profileRadioGroup mt-30">
              <label htmlFor="radio-group" className="formLabel">
                Key areas of professional interest or Domain of expertise <span className={"impRed"}>*</span>
              </label>
              <div className="feedbackRadioGroup">
                
                {areasOfInterest?.map((values, i) => (
                  <div className="radioBtnOuter" key={i}>
                    <input
                      type="checkbox"
                      id={`test${values}`}
                      name="interests"
                      value={values}
                       checked={data?.interests.includes(values)}
                      onChange={handleChange}
                    />
                    <label htmlFor={`test${values}`}>{values}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="formGroup profileRadioGroup mt-30">
              <label htmlFor="radio-group" className="formLabel">
              Topics you’d like to mentor on?  <span className={"impRed"}>*</span>
              </label>
              <div className="feedbackRadioGroup">
                {topics?.map((values, i) => (
                  <div className="radioBtnOuter" key={i}>
                    <input
                      type="checkbox"
                      id={`test${values}`}
                      name="topics"
                      value={values}
                       checked={data?.topics.includes(values)}
                       onChange={handleChange}
                    />
                    <label htmlFor={`test${values}`}>{values}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className='ButtonGroup'>
              <div></div>
              <Button className={'blackButton'} name={'Save Mentor'} onClick={handleSubmit}/>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default AddMentorPopup