import React, { useState } from 'react'
import './FeedbackStyle.css'
const Feedback = () => {
    const [tabToggler, setTabToggler] = useState(0)
    return (
        <div className="feedbackContainerOuter">
            <div className="pageHeader">
                {/* <span className="greyText">All Mentors / Mentor Profile / </span> */}
                <p className="heading">Feedback</p>
            </div>
            <div className="feedbackContainer">
                <div className="cardsOuter">
                    <div className="card">
                        <img src="/assets/img/demoUser.png" alt="" />
                        <div className="cardDetails">
                            <p className="name">John Doe</p>
                            <p className="email">john@gmail.com</p>
                        </div>
                        <div className="primaryTag">Mentor</div>
                    </div>
                    <div className="card">
                        <img src="/assets/img/demoUser.png" alt="" />
                        <div className="cardDetails">
                            <p className="name">Subhro Bhattacharya</p>
                            <p className="email">Subhro Bhattacharya@gmail.com</p>
                        </div>
                        <div className="primaryTag">Mentee</div>
                    </div>
                </div>
                <div className="tabsContainer">
                    <div className={`tab ${tabToggler === 0 && 'active'}`} onClick={() => setTabToggler(0)} >Mentee Feedback</div>
                    <div className={`tab ${tabToggler === 1 && 'active'}`} onClick={() => setTabToggler(1)} >Mentor Feedback</div>
                </div>
                <div className="tabContentOuter">
                    <div className={`tabContent ${tabToggler === 0 && 'active'}`}>
                        <div className="formGroup">
                            <label htmlFor="radio-group" className="formLabel">How would you rate the communication skills of the candicate? *</label>
                            <div className="feedbackRadioGroup">
                                <p className="label">Poor</p>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test1" name="radio-group" />
                                    <label for="test1">1</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test2" name="radio-group" />
                                    <label for="test2">2</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test3" name="radio-group" />
                                    <label for="test3">3</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test4" name="radio-group" />
                                    <label for="test4">4</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test5" name="radio-group" />
                                    <label for="test5">5</label>
                                </div>
                                <p className="label">Outstanding</p>
                            </div>
                        </div>
                    </div>
                    <div className={`tabContent ${tabToggler === 1 && 'active'}`}>
                        <div className="formGroup">
                            <label htmlFor="radio-group" className="formLabel">How would you rate the communication skills of the candicate? *</label>
                            <div className="feedbackRadioGroup">
                                <p className="label">Poor</p>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test1" name="radio-group" />
                                    <label for="test1">1</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test2" name="radio-group" />
                                    <label for="test2">2</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test3" name="radio-group" />
                                    <label for="test3">3</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test4" name="radio-group" />
                                    <label for="test4">4</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test5" name="radio-group" />
                                    <label for="test5">5</label>
                                </div>
                                <p className="label">Outstanding</p>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label htmlFor="radio-group" className="formLabel">How would you rate the communication skills of the candicate? *</label>
                            <div className="feedbackRadioGroup">
                                <p className="label">Poor</p>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test1" name="radio-group" />
                                    <label for="test1">1</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test2" name="radio-group" />
                                    <label for="test2">2</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test3" name="radio-group" />
                                    <label for="test3">3</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test4" name="radio-group" />
                                    <label for="test4">4</label>
                                </div>
                                <div className="radioBtnOuter">
                                    <input type="radio" id="test5" name="radio-group" />
                                    <label for="test5">5</label>
                                </div>
                                <p className="label">Outstanding</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback