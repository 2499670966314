import moment from 'moment/moment';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Bookings({ isActive, details }) {

    const navigate = useNavigate();
    const [isUpcomingCalls, setIsUpcomingCalls] = useState(true);

    return (
        <>
            <div className={`tabContent ${isActive && 'active'}`}>
                <div className="togglers">
                    <div className={`tabToggler ${isUpcomingCalls && "active"}`} onClick={() => setIsUpcomingCalls(true)} >Upcoming Calls</div>
                    <div className={`tabToggler ${!isUpcomingCalls && "active"}`} onClick={() => setIsUpcomingCalls(false)} >Past Calls</div>
                </div>
                <div className={`tabContent ${isUpcomingCalls && 'active'}`}>
                    <div className="cardsOuter">
                        {details?.upcomingCalls?.length > 0 ? details?.upcomingCalls?.map((item, index) => (
                            <div className="card">
                                <div className="cardDetails">
                                    <img src={item?.GetPreppedMentee?.profileImg ? item?.GetPreppedMentee?.profileImg : "/assets/img/demoUser.png"} alt="" className='cardImg' />
                                    <div className="callerDetails">
                                        <p className="name">{item?.GetPreppedMentee?.firstName ? item?.GetPreppedMentee?.firstName + " " + item?.GetPreppedMentee?.lastName : "---"}</p>
                                        <p className="mail">{item?.GetPreppedMentee?.email ? item?.GetPreppedMentee?.email : "-----"}</p>
                                        <div className="tags">
                                            <div className="greyTag">{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("ddd DD MMM YYYY") : "---"}</div>
                                            <div className="primaryTag">{item?.time ? item?.time : "---"}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <button className='primaryOutlineBtn'>View Feedback</button> */}
                            </div>
                        )) : "No calls yet"
                        }
                    </div>
                </div>
                <div className={`tabContent ${!isUpcomingCalls && 'active'}`}>
                    <div className="cardsOuter">
                        {details?.pastCalls?.length > 0 ? details?.pastCalls?.map((item, index) => (
                            <div className="card" key={index}>
                                <div className="cardDetails">
                                    <img src={item?.GetPreppedMentee?.profileImg ? item?.GetPreppedMentee?.profileImg : "/assets/img/demoUser.png"} alt="" className='cardImg' />
                                    <div className="callerDetails">
                                        <p className="name">{item?.GetPreppedMentee?.firstName ? item?.GetPreppedMentee?.firstName + " " + item?.GetPreppedMentee?.lastName : "---"}</p>
                                        <p className="mail">{item?.GetPreppedMentee?.email ? item?.GetPreppedMentee?.email : "-----"}</p>
                                        <div className="tags">
                                            <div className="greyTag">{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("ddd DD MMM YYYY") : "---"}</div>
                                            <div className="primaryTag">{item?.time ? item?.time : "---"}</div>
                                        </div>
                                    </div>
                                </div>
                                <button className='primaryOutlineBtn' onClick={() => navigate(`/allCallDetails/${item?.id}`)}>View Feedback</button>
                            </div>
                        )) : "No calls yet"
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bookings;