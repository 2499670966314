import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorToast from '../../components/Toast/ErrorToast';
import { clearState } from '../../reducer/mentorSlice';
import { userSelector } from '../../reducer/userSlice';
import './authenticationStyle.css';
import ConfirmPassword from './ConfirmPassword';
import ForgotPassword from './ForgotPassword';
import LoginPage from './LoginPage';
import SignupPage from './SignupPage';

const Authentication = () => {
  const { isError, errorMessage } = useSelector(userSelector);
  const [pageToggler, setPageToggler] = useState(0);
  const [errMessage, seterrMessage] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      seterrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isError])


  return (
    <>
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="autheticationWrapper">
        <div className="autheticationBox">
          <div className="loginLeft">
            {pageToggler === 0 && <LoginPage setPageToggler={setPageToggler} />}
            {/* {pageToggler === 1 && <SignupPage setPageToggler={setPageToggler} />} */}
            {pageToggler === 2 && <ForgotPassword setPageToggler={setPageToggler} />}
            {/* {pageToggler === 3 && <ConfirmPassword setPageToggler={setPageToggler} />} */}
          </div>
          <div className="loginRight">
            <img src="/assets/img/LoginBG.png" alt="" loading='lazy' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Authentication