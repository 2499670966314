import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Bookings from './Bookings';
// import MenteeFeedback from './';
import './MentorProfileStyle.css'
import Overview from './Overview';
import WeeklySlots from './WeeklySlots';
import PageLoader from "../../../components/PageLoader/PageLoader";
import { adminSliceData, getSpecificMentor } from '../../../reducer/adminSlice';
import { useParams } from 'react-router-dom';

const MentorProfile = () => {

    const [tabToggler, setTabToggler] = useState(0);
    const { isFetching, mentorDetail } = useSelector(adminSliceData);
    const { mentorId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSpecificMentor(mentorId));
    }, []);

    return (
        <>
            {isFetching && <PageLoader />}
            <div className="mentorProfileOuter">
                <div className="pageHeader">
                    <p className="heading"><span className="greyText">All Mentors / </span> Mentor's Profile</p>
                </div>
                <div className="mentorProfileContainer">
                    <div className="mentorProfile">
                        <img src={mentorDetail?.mentorDetails?.profileImg ? mentorDetail?.mentorDetails?.profileImg : "/assets/img/demoUser.png"} alt="" className="mentorImg" />
                        <div className="mentorDetails">
                            <p className="mentorName">{mentorDetail?.mentorDetails?.firstName ? `${mentorDetail?.mentorDetails?.firstName} ${mentorDetail?.mentorDetails?.lastName}` : "--"}
                                {mentorDetail?.mentorDetails?.linkedin && <div className="linkdinBtn" onClick={() => window.open(mentorDetail?.mentorDetails?.linkedin)}>
                                    Linkedin
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.8125 13.75H2.1875" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.875 2.1875L3.125 10.9375" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.875 8.60625V2.1875H5.45625" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>}
                            </p>
                            <p className="designation">{mentorDetail?.mentorDetails?.GetPrepProfession?.designation ? `${mentorDetail?.mentorDetails?.GetPrepProfession?.designation}, ${mentorDetail?.mentorDetails?.GetPrepProfession?.company}` : "---"}</p>
                        </div>


                    </div>
                    <div className="tabsContainer">
                        <div className={`tab ${tabToggler === 0 && 'active'}`} onClick={() => setTabToggler(0)} >Overview</div>
                        <div className={`tab ${tabToggler === 1 && 'active'}`} onClick={() => setTabToggler(1)} >Weekly Slots</div>
                        <div className={`tab ${tabToggler === 2 && 'active'}`} onClick={() => setTabToggler(2)} >Bookings</div>
                        {/* <div className={`tab ${tabToggler === 3 && 'active'}`} onClick={() => setTabToggler(3)} >Feedback</div> */}
                    </div>
                    <div className="tabContentOuter mentorRequest">
                        <Overview
                            isActive={tabToggler === 0}
                            details={mentorDetail?.mentorDetails}
                        />
                        <WeeklySlots
                            isActive={tabToggler === 1}
                            details={mentorDetail?.mentorDetails?.getpreppedmentorinterviewslots}
                        />
                        <Bookings
                            isActive={tabToggler === 2}
                            details={mentorDetail}
                        />
                        {/* <MenteeFeedback 
                        isActive={tabToggler === 3} 
                    /> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MentorProfile