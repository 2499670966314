import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PageLoader from "../../../components/PageLoader/PageLoader";
import {
  adminSliceData,
  clearState,
  getAllEvents,
  getAllMentee,
} from "../../../reducer/adminSlice";
import ErrorToast from "../../../components/Toast/ErrorToast";
// import './MenteeListStyle.css'

const Events = () => {
  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isFetching, isError, errorMessage, allEvents } =
    useSelector(adminSliceData);

  useEffect(() => {
    dispatch(getAllEvents());
  }, []);

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isError]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="menteeOuter">
        <div className="pageHeader">
          <p className="heading">Event List ({allEvents?.length ?? 0})</p>
        </div>
        <div className="tableContainer menteeList">
          <table className="studentTable" cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                <th>Event Name</th>
                <th>Description</th>
                <th>From</th>
                <th>To</th>
                <th>Registrations</th>
              </tr>
            </thead>
            <tbody>
              {allEvents?.length > 0
                ? allEvents?.map((item, index) => (
                    <tr key={index}>
                      <td key={index}>{item?.title ? item?.title : "---"}</td>
                      <td>{item?.description ? item?.description : "---"}</td>
                      <td>
                        {item?.fromDate
                          ? moment(item?.fromDate)
                              .utcOffset("-00:00")
                              .format("DD MMMM, YYYY")
                          : "---"}
                      </td>
                      <td>
                        {item.toDate
                          ? moment(item?.toDate)
                              .utcOffset("-00:00")
                              .format("DD MMMM, YYYY")
                          : "---"}
                      </td>
                      <td
                        className="pointer clickable"
                        onClick={() => navigate(`/eventLeads/${item?.id}`)}
                      >
                        {item?.registrationCount
                          ? item?.registrationCount
                          : 0}
                      </td>
                    </tr>
                  ))
                : "No data available"}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Events;
