import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import "rc-time-picker-date-fns/assets/index.css";

function WeeklySlots({ isActive, details }) {

    const [weeklySlots, setWeeklySlots] = useState({
        sun: [],
        mon: [],
        tue: [],
        wed: [],
        thur: [],
        fri: [],
        sat: []
    });
    const weekDays = ["sun","mon","tue","wed","thur","fri","sat"];

    useEffect(() => {
        if(details?.length > 0) {
            details.forEach((item) => {
                const day = new Date(item.startTime).getDay();
                setWeeklySlots(prev => ({
                    ...prev,
                    [weekDays[day]]: [...prev[weekDays[day]], `${moment.utc(item.startTime).format("hh:mm A")} - ${moment.utc(item.endTime).format("h:mm A")}`]
                }))
            })
        }
    },[details]);

    return (
        <div className={`tabContent ${isActive && 'active'}`}>
            <div className="weekDaysWrap">

                {weekDays?.map((day,i) => (
                    <div className="weekdayBox flexbox" key={i}>
                    <div className="weekday">{day.toUpperCase()}</div>
                    <div className="dayAvaiability flexbox">
                        {weeklySlots[day]?.length > 0 ? weeklySlots[day]?.map((item) => (
                            <div className="timeWrapBox">
                                <div className="timeBox">{item.substr(0,8)}</div>
                                <span className="stroke"></span>
                                <div className="timeBox">{item.substr(11)}</div>
                            </div>
                        )): "Unavailable"}
                    </div>
                </div>
                ))}

            </div>
        </div>
    )
}

export default WeeklySlots