import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import PageLoader from "../../../components/PageLoader/PageLoader";
import {
  adminSliceData,
  clearState,
  getAllEventRegistrations,
  getAllMentee,
} from "../../../reducer/adminSlice";
import ErrorToast from "../../../components/Toast/ErrorToast";
// import './MenteeListStyle.css'

const EventLeads = () => {
  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { isFetching, isError, errorMessage, eventRegistrations } =
    useSelector(adminSliceData);

  useEffect(() => {
    dispatch(getAllEventRegistrations(id));
  }, []);

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isError]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="menteeOuter">
        <div className="pageHeader">
          <p className="heading">
            <span
              className="greyText pointer"
              onClick={() => navigate("/events")}
            >
              Events /{" "}
            </span>{" "}
            Event Leads ({eventRegistrations?.length ?? 0})
          </p>
        </div>
        <div className="tableContainer menteeList">
          <table className="studentTable" cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Registration Date</th>
              </tr>
            </thead>
            <tbody>
              {eventRegistrations?.length > 0
                ? eventRegistrations?.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.name ? item?.name : "---"}</td>
                      <td>{item?.email ? item?.email : "---"}</td>
                      <td>{item?.mobile ? item?.mobile : "---"}</td>
                      <td>
                        {item.registeredOn
                          ? moment(item.registeredOn).format("DD MMMM, YYYY")
                          : "---"}
                      </td>
                    </tr>
                  ))
                : "No data available"}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default EventLeads;
